import React, { useCallback, useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import ArticleIcon from '@mui/icons-material/Article';
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfSharpIcon from '@mui/icons-material/PictureAsPdfSharp';
import classes from '../../src/Article/mediaLib/Resource.module.css';

import { svgToSrc, dmsUrl } from '@ekultur/ui-schema-components';

export const Resource = ({ media, maxRetries = 10 }) => {
    const [retryCount, setRetryCount] = useState(0);
    const url = ['video', 'audio'].includes(media.type) ? media.url : dmsUrl(media, 10);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setRetryCount(0);
    }, [url]);

    const handleImageError = useCallback(() => {
        setTimeout(() => {
            setRetryCount(r => {
                if (url && r < maxRetries) {
                    return r + 1;
                } else {
                    setLoading(false);
                    return r;
                }
            });
        }, 3000);
    }, [url, maxRetries]);

    const imageLoaded = () => {
        setLoading(false);
    };

    switch (media.type) {
        case 'video':
            return (
                <video controls style={{ maxWidth: '100%' }}>
                    <source src={url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            );

        case 'audio':
            return (
                <audio controls style={{ maxWidth: '100%' }}>
                    <source src={url} type="audio/mp3" />
                    Your browser does not support the audio tag.
                </audio>
            );

        case 'document':
            if (media.mimetype === 'application/pdf') {
                return (
                    <PictureAsPdfSharpIcon className={classes.iconStyle} data-testid="pdf-icon" />
                );
            } else if (
                media.mimetype ===
                'application/vnd.openxmlformats-officedocument.presentationml.presentation'
            ) {
                return <DescriptionIcon className={classes.iconStyle} data-testid="ppt-icon" />;
            } else {
                return <ArticleIcon className={classes.iconStyle} data-testid="doc-icon" />;
            }

        default:
            return media.mimetype === 'image/svg+xml' && media.source ? (
                <img className="svg" src={svgToSrc(media.source)} alt={media.filename} />
            ) : (
                <div>
                    <CircularProgress style={{ display: loading ? 'block' : 'none' }} />
                    <img
                        style={{ maxWidth: '100%', display: loading ? 'none' : 'block' }}
                        src={`${url}?dimension=167x167&failhard=true&bust=${retryCount}`}
                        onError={handleImageError}
                        onLoad={imageLoaded}
                        alt={media.filename}
                    />
                </div>
            );
    }
};
