import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
    FormControl,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    Input,
    InputLabel,
    Button,
    Chip,
    Box,
    FormGroup,
    Radio
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Search from '@mui/icons-material/Search';

import { kitFetch } from '@ekultur/fetch';
import { Accordion } from '@ekultur/accordion';

import { config as appConfig } from '../../config';
import {
    requestResources,
    requestDmSearch,
    RESET_MEDIA_SEARCH,
    RESET_MEDIA_SEARCH_FLAG
} from '../../redux/actions/media';

import { Error } from '../../common/Error';
import { ProgressIndicator } from '../../common/ProgressIndicator';
import { useMashupStyles } from '../../Mashup/mashupStyles';

import { imageMapper } from './tpl/image';
import { dmObjMapper } from './tpl/dmObj';
import { artifactTypes } from '../schema/searchFilters/artifactTypeEnum';
import { sort } from '../schema/searchFilters/sortEnum';
import { order } from '../schema/searchFilters/sortOrderEnum';

import { ImageSelector } from './ImageSelector';
import { Resource } from '../../common/Resource';
import { UppyDashboard } from '../../common/UppyDashboard';

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
    header: {
        display: 'flex',
        height: spacing(8),
        backgroundColor: '#f2f2f2',
        boxShadow: '0 1.5px 4px rgba(0, 0, 0, 0.12)',
        zIndex: 1,
        '& input': {
            width: '100%',
            height: '100%',
            padding: 0
        },
        '& input, & button': {
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent'
        },
        '& button': {
            cursor: 'pointer'
        }
    },
    searchIcon: {
        width: spacing(8),
        height: spacing(8),
        padding: spacing(2.5),
        boxSizing: 'border-box'
    },
    resWrapper: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0
    },
    resSection: {
        flex: '0 1 auto',
        minHeight: spacing(7),
        marginBottom: spacing(1),
        boxShadow: '0 1.5px 4px rgba(0, 0, 0, 0.12)',
        '&:first-of-type': {
            marginTop: spacing(1)
        },
        '&.expanded': {
            minHeight: '49%'
        },
        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '100%',
            padding: 0,
            boxShadow: 'none'
        },
        '& .pagination': {
            marginBottom: '1em'
        },
        '& header': {
            width: '100%',
            boxShadow: '0 1.5px 4px rgba(0, 0, 0, 0.12)',

            '& div[data-collapsible="true"]': {
                height: spacing(7),
                marginRight: 0,
                zIndex: 100,
                background: '#fafafa'
            },

            '& + div.MuiCollapse-root': {
                zIndex: 0,
                overflowY: 'scroll'
            }
        }
    },
    uploadedWrapper: {
        display: 'flex',
        flexFlow: 'wrap',
        justifyContent: 'space-between',
        paddingTop: spacing(2),
        '& .image--preview': {
            flex: '1 1 auto',
            '& .svg': {
                width: '167px',
                height: '167px'
            },
            '& .document': {
                display: 'flex',
                alignItems: 'center',
                '& img': {
                    width: '48px'
                },
                '& span': {
                    padding: '0 0 0 .25em'
                }
            },
            '& .mediaWrapper': {
                display: 'flex',
                flexDirection: 'column',
                background: palette.action.selected,
                padding: spacing(0.5),
                borderRadius: spacing(0.5),
                '& span': {
                    fontSize: '.8em',
                    textAlign: 'center'
                }
            },
            '&:hover': {
                cursor: 'pointer'
            },
            '&.selected': {
                opacity: 0.5
            }
        }
    },
    filters: {
        display: 'flex',
        flexFlow: 'wrap',
        marginBottom: '1em',
        '& > p': {
            width: '100%',
            marginBottom: 0
        },
        '& > div': {
            width: '100px',
            flex: '1 1',
            marginRight: spacing(1),
            '&:first-of-type': {
                flex: '1 1 auto',
                marginTop: '.7rem'
            }
        }
    },
    moreBtn: {
        marginTop: spacing(1),
        marginBottom: spacing(1)
    },
    mediaItem: {
        display: 'flex',
        flex: '1 0 0',
        position: 'relative',
        marginTop: spacing(1),
        padding: spacing(1),
        backgroundColor: '#f5f5f5',
        border: '1px solid #ddd',
        cursor: 'pointer',
        '&.selected': {
            '& .image--preview, & .fieldWrapper': {
                opacity: 0.5
            }
        },
        '& .fieldWrapper': {
            display: 'flex',
            flexFlow: 'wrap',
            width: '100%',
            '& label + .MuiInput-formControl': {
                marginTop: spacing(1.5)
            }
        },
        '& .image--preview': {
            position: 'relative',
            minWidth: spacing(9),
            minHeight: spacing(9),
            marginRight: '.5em',
            background: '#423f3c',
            '& img': {
                position: 'absolute',
                top: 0,
                bottom: 0,
                maxWidth: '100%',
                maxHeight: '100%',
                width: 'auto',
                height: 'auto',
                margin: 'auto'
            },
            '& .imgCount': {
                position: 'absolute',
                background: 'rgba(255, 255, 255, .5)',
                padding: '0 4px',
                fontSize: '13px'
            }
        },
        '& .popper': {
            zIndex: 1,
            '& .paper': {
                padding: spacing(1)
            },
            '& img': {
                padding: spacing(0.25),
                '&.selected': {
                    opacity: 0.5
                }
            }
        },
        '& p': {
            width: '100%',
            margin: 0
        },
        '& > .imgGrid': {
            display: 'none'
        },
        '&:hover > .imgGrid': {
            display: 'block'
        }
    },
    chip: props => ({
        ...props.typeChip,
        marginBottom: spacing(0.5)
    })
}));

let timer;

export const MediaLib = ({ onMediaSelected, onMediaLibClose, config }) => {
    const dispatch = useDispatch(),
        theme = useTheme(),
        { ...mashupStyles } = useMashupStyles(theme),
        classes = useStyles(mashupStyles),
        { items, dmOnly, mediaTypeFilter, schoolFilter = false, virtualFilter = false } = config,
        [resourcesExpanded, setResourcesExpanded] = useState(true),
        [searchExpanded, setSearchExpanded] = useState(true),
        [ownerFilter, setOwnerFilter] = useState(''),
        [typeFilter, setTypeFilter] = useState(''),
        [sortFilter, setSortFilter] = useState(''),
        [orderFilter, setOrderFilter] = useState(''),
        [start, setStart] = useState(0),
        { data, fetching, countFound } = useSelector(state => state.resources),
        { ownerUniqueId } = useSelector(state => state.app),
        { activeMuseum } = useSelector(state => state.user),
        {
            data: searchRes,
            fetching: searchFetching,
            error: searchError
        } = useSelector(state => state.dmSearch),
        { t } = useTranslation(['dm.media'], { useSuspense: false }),
        [isUppyOpen, setIsUppyOpen] = useState(false),
        [mediaType, setMediaType] = useState('media'),
        [q, setQ] = useState(''),
        [searchTerm, setSearchTerm] = useState(''),
        [page, setPage] = useState(1),
        [noOfPages, setNoOfPages] = useState(0),
        itemsPerPage = 24;

    useEffect(() => {
        setQ('');
        setOwnerFilter('');
        setTypeFilter('');
        setSortFilter('');
    }, [dmOnly, mediaTypeFilter, schoolFilter, virtualFilter]);

    useEffect(() => {
        dispatch(
            requestResources(
                ownerUniqueId,
                searchTerm,
                itemsPerPage,
                (page - 1) * itemsPerPage,
                mediaType
            )
        );
    }, [dispatch, ownerUniqueId, searchTerm, itemsPerPage, page, mediaType]);

    useEffect(() => {
        let ownerFilterStr = '',
            typeFilterStr = '';

        if (ownerFilter) {
            ownerFilterStr = ` owner:${ownerFilter}`;
        }

        if (schoolFilter && !virtualFilter) {
            typeFilterStr = ' type:school';
        } else if (virtualFilter && !schoolFilter) {
            typeFilterStr = ' type:virtual';
        } else if (typeFilter) {
            typeFilterStr = ` type:${typeFilter}`;
        }

        const query = `${searchTerm}${ownerFilterStr}${typeFilterStr}${
            sortFilter ? ` sort:${sortFilter}` : ''
        }${orderFilter ? ` sort_order:${orderFilter}` : ''}`;

        if (query) {
            dispatch(requestDmSearch(`${query}+start:${start}`, start === 0));
        } else {
            dispatch({ type: RESET_MEDIA_SEARCH });
        }
    }, [
        dispatch,
        ownerUniqueId,
        searchTerm,
        start,
        ownerFilter,
        typeFilter,
        sortFilter,
        orderFilter,
        schoolFilter,
        virtualFilter
    ]);

    useEffect(() => {
        setStart(0);
    }, [ownerFilter, typeFilter, sortFilter, orderFilter]);

    useEffect(() => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setSearchTerm(q);
            setPage(1);
        }, 500);
    }, [q]);

    useEffect(() => {
        setNoOfPages(Math.ceil(countFound / itemsPerPage));
    }, [countFound]);

    useEffect(() => {
        setMediaType(mediaTypeFilter || 'media');
    }, [mediaTypeFilter]);

    const handleUppyDashboardClose = e => {
        setIsUppyOpen(false);
    };

    const uppyUpload = () => {
        setIsUppyOpen(true);
    };

    const changeFilter = (setter, value) => {
        dispatch({ type: RESET_MEDIA_SEARCH_FLAG });
        setter(value);
    };

    const changeOwnerFilter = value =>
        changeFilter(setOwnerFilter, value ? activeMuseum.short_name : '');
    const changeFilterType = value => changeFilter(setTypeFilter, value);
    const changeSortFilter = value => changeFilter(setSortFilter, value);
    const changeOrderFilter = value => changeFilter(setOrderFilter, value);
    const changeQueryText = value => changeFilter(setQ, value);

    const closeHandler = e => {
        onMediaLibClose?.();
    };

    const getPictureMeta = async (dimu_code, image_id) => {
        try {
            return await kitFetch(
                `${appConfig.get('articleApi')}media/meta/${dimu_code}/${image_id}`
            );
        } catch (e) {
            console.log(e);
        }
    };

    const mediaSelectedHandler = async (obj, selected, update = false) => {
        let img = { ...obj };

        if (!selected && img.artifactType.toLowerCase() !== 'photograph') {
            const meta = await getPictureMeta(img.dimuId, img.image_id);

            if (meta?.photographer) {
                img.local.credit = `${meta.photographer} / ${img.owner_name}`;
            }
        }

        onMediaSelected?.([img], selected, update);
    };

    const handleChangePagination = (e, value) => {
        setPage(value);
    };

    const itemSelected = (item, compareProp = 'dimuId') => {
        const res = items?.findIndex(x => x && x[compareProp] === item[compareProp]);

        if (res >= 0) {
            return items[res];
        }

        return false;
    };

    const renderMediaCard = item => {
        const obj = dmObjMapper(item),
            selected = itemSelected(obj, 'dimuId'),
            multipleImg =
                item.artifact_type !== 'Folder' && item.image_ids && item.image_ids.length > 1;

        const onClick = () => {
            mediaSelectedHandler(obj, selected);
        };

        const handleSelect = (selectedItem, remove = false) => {
            if (onMediaSelected) {
                const sObj = dmObjMapper(selectedItem);
                mediaSelectedHandler(sObj, remove ? sObj : false);
            }
        };

        return (
            <div
                key={obj.objectId}
                className={`${classes.mediaItem}${selected ? ' selected' : ''}`}
                onClick={!multipleImg ? onClick : undefined}
            >
                <ImageSelector
                    items={items}
                    item={item}
                    dmOnly={dmOnly}
                    selected={selected}
                    multipleImg={multipleImg}
                    handleSelect={handleSelect}
                />
                <div className="fieldWrapper" onClick={multipleImg ? onClick : undefined}>
                    <Chip
                        className={classes.chip}
                        size="small"
                        variant="outlined"
                        label={obj.artifactType}
                    />
                    <Chip
                        className={classes.chip}
                        size="small"
                        variant="outlined"
                        label={obj.objectId}
                    />
                    <p>{obj.title}</p>
                </div>
            </div>
        );
    };

    const renderResources = (tile, index) => {
        if (typeof tile === 'undefined') {
            return null;
        }

        const mappedItem = imageMapper(tile),
            selected = itemSelected(mappedItem, 'image_id');

        const onClick = e => {
            e.preventDefault();
            onMediaSelected?.([mappedItem], selected);
        };

        return (
            <ImageListItem
                onClick={e => onClick(e)}
                key={tile?.id}
                sx={{ width: 'auto' }}
                className={`image--preview${selected ? ' selected' : ''}`}
            >
                <div className={classes.media}>
                    <Resource media={tile} />
                </div>
                <ImageListItemBar
                    title={tile?.filename}
                    subtitle={
                        <span>
                            {t('owner', 'eier')}: {tile?.owner_identifier && tile.owner_identifier}
                        </span>
                    }
                />
            </ImageListItem>
        );
    };

    return (
        <>
            <div className={classes.header}>
                <Search className={classes.searchIcon} />
                <input
                    type="text"
                    value={q}
                    placeholder={t('search-placeholder', 'Finn media')}
                    onChange={e => changeQueryText(e.target.value)}
                />
                <button onClick={closeHandler}>
                    <strong>«&nbsp;</strong>Lukk
                </button>
            </div>
            <div className={classes.resWrapper}>
                {!dmOnly && (
                    <section
                        className={`${classes.resSection}${resourcesExpanded ? ' expanded' : ''}`}
                        style={!countFound && resourcesExpanded ? { minHeight: 125 } : {}}
                    >
                        <Accordion
                            component="div"
                            title={`${countFound || 0} ${t(
                                'resource-hits',
                                'treff i egne medier'
                            )}`}
                            expanded={resourcesExpanded}
                            onToggle={() => setResourcesExpanded(!resourcesExpanded)}
                        >
                            {!mediaTypeFilter && (
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={mediaType === 'media'}
                                                onChange={() => setMediaType('media')}
                                                name="all"
                                                color="primary"
                                                value="media"
                                            />
                                        }
                                        label={t('mediaType_all', 'Alle')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={mediaType === 'image'}
                                                onChange={() => setMediaType('image')}
                                                name="image"
                                                color="primary"
                                                value="image"
                                            />
                                        }
                                        label={t('mediaType_images', 'Bilder')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={mediaType === 'audio'}
                                                onChange={() => setMediaType('audio')}
                                                name="audio"
                                                color="primary"
                                                value="audio"
                                            />
                                        }
                                        label={t('mediaType_audio', 'Audio')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={mediaType === 'video'}
                                                onChange={() => setMediaType('video')}
                                                name="video"
                                                color="primary"
                                                value="video"
                                            />
                                        }
                                        label={t('mediaType_video', 'Video')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={mediaType === 'document'}
                                                onChange={() => setMediaType('document')}
                                                name="document"
                                                color="primary"
                                                value="document"
                                            />
                                        }
                                        label={t('mediaType_document', 'Document')}
                                    />
                                </FormGroup>
                            )}
                            <Input
                                id="file-upload"
                                type="button"
                                onClick={uppyUpload}
                                sx={{ display: 'none' }}
                            />
                            <label htmlFor="file-upload">
                                <Button
                                    variant="contained"
                                    startIcon={<CloudUploadIcon />}
                                    component="span"
                                    sx={{ margin: '0 1em 1em 0' }}
                                >
                                    {t('upload_file', 'Last opp media')}
                                </Button>
                            </label>
                            {fetching || !data?.resources ? (
                                <ProgressIndicator />
                            ) : (
                                <>
                                    <div className={classes.uploadedWrapper}>
                                        <ImageList cols={3}>
                                            {data?.resources && data.resources.map(renderResources)}
                                        </ImageList>
                                    </div>
                                    {countFound > itemsPerPage && (
                                        <Box component="div" className="pagination">
                                            <Pagination
                                                count={noOfPages}
                                                page={page}
                                                onChange={handleChangePagination}
                                                defaultPage={1}
                                                color="primary"
                                                size="large"
                                                showFirstButton
                                                showLastButton
                                                classes={{ ul: classes.paginator }}
                                            />
                                        </Box>
                                    )}
                                </>
                            )}
                        </Accordion>
                    </section>
                )}
                {searchError && <Error />}
                <section className={`${classes.resSection}${searchExpanded ? ' expanded' : ''}`}>
                    <Accordion
                        component="div"
                        title={`${searchRes?.count_found || 0} ${t(
                            'dm-hits',
                            'treff i Digitaltmuseum'
                        )}`}
                        expanded={searchExpanded}
                        onToggle={() => setSearchExpanded(!searchExpanded)}
                    >
                        <div className={classes.filters}>
                            <p>{t('search_title', 'Begrens søket til:')}</p>
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={e => changeOwnerFilter(e.target.checked)}
                                            checked={!!ownerFilter}
                                        />
                                    }
                                    label={`${t('search-filter_owner', 'Egen samling')} (${
                                        activeMuseum.short_name
                                    })`}
                                />
                            </FormControl>
                            {!virtualFilter && !schoolFilter && (
                                <FormControl>
                                    <InputLabel id="artifactTypeLabel">
                                        {t('search-filter_type', 'Type')}
                                    </InputLabel>
                                    <Select
                                        labelId="artifactTypeLabel"
                                        label={t('search-filter_type', 'Type')}
                                        value={typeFilter}
                                        onChange={e => changeFilterType(e.target.value)}
                                    >
                                        {artifactTypes.map(at => (
                                            <MenuItem key={at.value} value={at.value}>
                                                {at.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                            <FormControl>
                                <InputLabel id="sortLabel">
                                    {t('search-filter_sort', 'Sortering')}
                                </InputLabel>
                                <Select
                                    labelId="sortLabel"
                                    label={t('search-filter_sort', 'Sortering')}
                                    value={sortFilter}
                                    onChange={e => changeSortFilter(e.target.value)}
                                >
                                    {sort.map(s => (
                                        <MenuItem key={s.value} value={s.value}>
                                            {s.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl>
                                <InputLabel id="orderLabel">
                                    {t('search-filter_order', 'Rekkefølge')}
                                </InputLabel>
                                <Select
                                    labelId="orderLabel"
                                    label={t('search-filter_order', 'Rekkefølge')}
                                    value={orderFilter}
                                    onChange={e => changeOrderFilter(e.target.value)}
                                >
                                    {order.map(o => (
                                        <MenuItem key={o.value} value={o.value}>
                                            {o.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        {searchRes?.objects?.map(renderMediaCard)}
                        {searchTerm && searchFetching !== false && !searchError && (
                            <ProgressIndicator />
                        )}
                        {searchRes && searchRes.count_found > searchRes.count_returned && (
                            <Button
                                variant="contained"
                                className={classes.moreBtn}
                                onClick={() => setStart(start + 30)}
                            >
                                {t('show-more', {
                                    count: searchRes.objects.length,
                                    total: searchRes.count_found,
                                    defaultValue: 'Hent flere treff (viser {{count}} av {{total}})'
                                })}
                            </Button>
                        )}
                    </Accordion>
                </section>
            </div>
            {isUppyOpen && <UppyDashboard closeHandler={handleUppyDashboardClose} />}
        </>
    );
};
